import React from 'react';
import logoWhite from 'images/common/ktp_logo_white.png';
function PopupAD({ popUpUrl }: { popUpUrl: string }) {
  return (
    <div>
      <img src={popUpUrl} alt='배너광고' className='w-screen' />
      <div className='flex items-center justify-center w-full h-[156px] bg-mono-100'>
        <img src={logoWhite} alt='' className='h-[50px] z-10' />
      </div>
    </div>
  );
}
export default PopupAD;
